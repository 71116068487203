<template>
  <div>
    <location />

    <div class="p12_view page wrap-1200">
      <h3 class="subtitle">{{ shopinfo.shopname }}</h3>
      <GmapMap
        :center="{
          lat: parseFloat(shopinfo.maploc2),
          lng: parseFloat(shopinfo.maploc1),
        }"
        :zoom="15"
        map-type-id="roadmap"
        style="width: 100%; height: 300px;"
      >
      </GmapMap>
      <div class="row g-0 border-top-dgray">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">매장명</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">{{ shopinfo.shopname }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">매장 유형</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">
                {{
                  shopinfo.shoptype == 1
                    ? "직영매장"
                    : "프랜차이즈매장"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">주소</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">
                {{ shopinfo.city }} {{ shopinfo.address }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">지역</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">{{ shopinfo.area }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">우편번호</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">{{ shopinfo.zipcode }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">이메일</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">
                <a :href="'mailto:' + shopinfo.email" target="_blank" class="more">
                  {{ shopinfo.email }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">전화</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">{{ shopinfo.phone }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">팩스</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">{{ shopinfo.fax }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">홈페이지</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">
                <a :href="shopinfo.website" target="_blank" class="more">
                  {{ shopinfo.website }}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col-4 d-table bg-gray">
              <label class="d-table-cell">장비 수</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell text-break">{{ shopinfo.systemcnt | comma }}대</p>
            </div>
          </div>
        </div>
      </div>
      <!--       <div class="start-m row g-0 center-btn">
        <div class="col-lg-4 d-table m-auto">
          <button
            class="btn btn-primary btn-xl"
            type="button"
            @click="
              $router.push({
                name: 'Stores',
                query: {
                  searchtype: $route.query.searchtype,
                  searchname: $route.query.searchname,
                },
              })
            "
          >
            목록
          </button>
        </div>
      </div> -->
      <div class="btn-bottom-wrapper">
        <b-button size="xl" variant="outline-primary" aria-label="목록" @click="
              $router.push({
                name: 'Stores',
                query: {
                  searchtype: $route.query.searchtype,
                  searchname: $route.query.searchname,
                },
              })
            ">목록</b-button>
      </div>

    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/findlocation";
import "@/filter/common";

export default {
  name: "StoresView",
  components: {
    location,
  },
  data() {
    return {
      shopno: this.$route.params.id,
      shopinfo: [],
    };
  },
  methods: {
    get_shopinfo() {
      ax.get_shopinfo(this.shopno, (flag, data) => {
        if (flag) {
          this.shopinfo = data.shopinfo;
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_shopinfo();
  },
};
</script>