<template>
  <div>
    <location />

    <div class="p48 page">
      <div class="wrap-1200">
        <b-form @submit="onSubmit">
          <div class="select-search list">
            <select
              class="border-right-0"
              aria-label=".border-right-0 example"
              name="searchtype"
              v-model="searchData.searchtype"
            >
              <option value="1">지역명</option>
              <option value="2">매장명</option>
            </select>
            <input
              type="text"
              class="form-control-lg"
              name="searchname"
              v-model="searchData.searchname"
            />
            <input
              class="btn btn-primary btn-lg search"
              type="submit"
              value=""
            />
          </div>
        </b-form>
      </div>
      <table
        class="board table wrap-1200"
        id="dataTable"
        width="100%"
        cellspacing="0"
      >
        <thead>
          <tr>
            <th class="col-auto d-lg-table-cell">지역</th>
            <th class="col-auto d-lg-table-cell">매장명</th>
            <th class="col-auto d-none d-lg-table-cell">전화</th>
            <th class="col-auto d-none d-lg-table-cell">주소</th>
            <th class="col-auto d-none d-lg-table-cell">장비 수</th>
            <th class="col-auto d-none d-lg-table-cell">편의시설</th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in items">
            <tr class="btn1" @click="toggle_tr(index)" :key="index">
              <td class="col-auto d-lg-table-cell">{{ item.area }}</td>
              <td class="col-auto d-lg-table-cell">
                <router-link
                  :to="{
                    name: 'StoresView',
                    params: { id: item.shopno },
                    query: {
                      searchtype: searchData.searchtype,
                      searchname: searchData.searchname,
                    },
                  }"
                  class="more"
                >
                  {{ item.shopname }}
                </router-link>
              </td>
              <td class="col-auto d-none d-lg-table-cell">{{ item.phone }}</td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.city }} {{ item.address }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.systemcnt }} 대
              </td>
              <td class="col-auto d-none d-lg-table-cell">{{ item.etc }}</td>
              <td class="td-arrow">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="col-auto d-lg-none d-md-tabel-cell"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td class="align-left bg-white" colspan="6">
                <!--
                <router-link
                  :to="{
                    name: 'StoresView',
                    params: { id: item.shopno },
                    query: {
                      searchtype: searchData.searchtype,
                      searchname: searchData.searchname
                    }
                  }"
                  class="btn1 btn_view"
                >
                  <img src="/img/ico_edit.png" />
                </router-link>
                -->
                <div class="layer1">
                  <div class="row g-0">
                    <div class="col">전화</div>
                    <div class="col-9">{{ item.phone }}</div>
                  </div>
                  <div class="row g-0">
                    <div class="col">주소</div>
                    <div class="col-9">{{ item.address }} {{ item.city }}</div>
                  </div>
                  <div class="row g-0">
                    <div class="col">장비 수</div>
                    <div class="col-9">{{ item.systemcnt }}대</div>
                  </div>
                  <div class="row g-0">
                    <div class="col">편의시설</div>
                    <div class="col-9">{{ item.etc }}</div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6" class="text-center">데이터가 없습니다.</td>
          </tr>
        </tbody>
      </table>
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation example"
        class="mt-3"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/findlocation";
import "@/filter/common";

export default {
  name: "Stores",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        searchtype: this.$route.query.searchtype || 1,
        searchname: this.$route.query.searchname || "",
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
    };
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_shoplist();
    },
  },
  methods: {
    get_shoplist() {
      ax.get_shoplist(this.searchData, (flag, data) => {
        if (flag) {
          if (data.shoplistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.shoplistcnt;
          this.page_total = Math.ceil(data.shoplistcnt / this.rows);
          this.items = data.shoplist;
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            searchtype: this.searchData.searchtype,
            searchname: this.searchData.searchname,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? `?searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}`
        : `?page=${pageNum}&searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_shoplist();
  },
};
</script>